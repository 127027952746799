
import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { NAVSChangeList } from "../../services/srvc-navs-realm";
import { format } from "date-fns";

const NavLineChart = (props) => {
  const { getNavRate } = props;
  const chartRef = useRef(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await NAVSChangeList({
          data: {
            unit: props.unit,
          },
        });
        if (res && res.data.list) {
          const items = res.data.list;
          setCurrentItems(items);
          const formattedDates = items.reverse().map((item) =>
            format(new Date(Number(item.crts)), "d MMM")
          );
          setDates(formattedDates);
          console.log("NavChart data---->", items);
          getNavRate(items[items.length - 1].navx);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [props.unit]);

  useEffect(() => {
    if (chartRef.current && currentItems.length > 0) {
      const ctx = chartRef.current.getContext("2d");

      const yValues = currentItems.map(
        (item) => parseFloat(item.navx.nmbr) / 1000000
      );

      const data = {
        labels: dates,
        datasets: [
          {
            label: "Rate",
            data: yValues,
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 2,
            fill: {
              target: "origin",
              above: "rgba(75, 192, 192, 0.1)",
              below: "rgba(75, 192, 192, 0)",
            },
            tension: 0.4,
            pointRadius: 0,
            pointHoverRadius: 5,
          },
        ],
      };

      const config = {
        type: "line",
        data: data,
        options: {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: "index",
              intersect: false,
              callbacks: {
                label: function (tooltipItem) {
                  return `Rate: ${tooltipItem.raw.toFixed(2)} INR`;
                },
                title: function (tooltipItems) {
                  return `Date: ${tooltipItems[0].label}`;
                },
              },
            },
          },
          layout: {
            padding: {
              top: 20,
              bottom: 20,
              left: 20,
              right: 20,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              // title: {
              //   display: true,
              //   text: "Date →",
              //   align: "center",
              // },
              ticks: {
                display: false, // hide ticks
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                display: false,
              },
              // title: {
              //   display: true,
              //   text: "NAV Rate (in INR) →",
              //   align: "center",
              // },
            },
          },
        },
      };

      const myChart = new Chart(ctx, config);

      return () => {
        myChart.destroy();
      };
    }
  }, [chartRef, currentItems, dates]);

  return (
    <div className="rounded-xd border" style={{ width: "100%", height: "100%" }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default NavLineChart;

