import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import {
  AssetsNAVChangeList,
  NAVSChangeList,
} from "../../services/srvc-navs-realm";
import { TransfersAssetGraph } from "../../services/srvc-transfers-assets-realm";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

const AssetsStatGraph = (props) => {
  const { id } = useParams();
  const chartRef = useRef(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [dateLable, setDateLabel] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await AssetsNAVChangeList({
          data: {
            asset: id,
          },
        });
        if (res && res.data) {
          const items = res.data.list;
          setCurrentItems(items);
          setDateLabel(
            items
              .reverse()
              .map((item) => format(new Date(Number(item.date)), "d MMM"))
          );
          console.log("NavGraph data--23-->", items);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [props.unit]);

    console.log("NavGraph dateLable-->", dateLable);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await TransfersAssetGraph({
  //         data: {
  //           asset: id,
  //         },
  //       });
  //       if (res && res.data) {
  //         const items = res.data;
  //         setCurrentItems(items);
  //         console.log("NavGraph data---->", items);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [props.unit]);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    const yValues =
      currentItems.length > 1
        ? currentItems.map((item) => item.navx)
        : currentItems.length == 1
        ? [currentItems[currentItems.length - 1]?.number, "0"]
        : [props.rate, "0"];
    // yValues.reverse();

    const data = {
      labels: dateLable,
      datasets: [
        {
          label: "Sale",
          data: yValues,
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 2,
          fill: {
            target: "origin",
            above: "rgba(75, 192, 192, 0.1)", // High opacity
            below: "rgba(75, 192, 192, 0)", // Low opacity
          },
          tension: 0.4, // Adjust the tension for a smoother curve
          pointRadius: 0,
        },
      ],
    };

    const config = {
      type: "line",
      data: data,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          layout: {
            padding: {
              top: 20,
              bottom: 20,
              left: 20,
              right: 20,
            },
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            // title: {
            //   display: true,
            //   text: "Date →",
            //   align: "center",
            // },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            // title: {
            //   display: true,
            //   text: "NAV Rate →",
            //   align: "center",
            // },
          },
        },
      },
    };

    const myChart = new Chart(ctx, config);

    return () => {
      myChart.destroy();
    };
  }, [chartRef, currentItems]);

  return (
    <div className="border p-3 rounded-xd" style={{ width: "100%", height: "50%" }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default AssetsStatGraph;
